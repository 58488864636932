<template>
  <svg
    :style="iconStyle"
    :width="width"
    :height="height"
    viewBox="0 0 28 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 16C18.4188 16 22 12.4187 22 8C22 3.58125 18.4188 0 14 0C9.58125 0 6 3.58125 6 8C6 12.4187 9.58125 16 14 16ZM19.6 18H18.5562C17.1687 18.6375 15.625 19 14 19C12.375 19 10.8375 18.6375 9.44375 18H8.4C3.7625 18 0 21.7625 0 26.4V29C0 30.6562 1.34375 32 3 32H25C26.6562 32 28 30.6562 28 29V26.4C28 21.7625 24.2375 18 19.6 18Z"
      fill="#FA6758"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '32',
    },
    width: {
      type: String,
      default: '28',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
